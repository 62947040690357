export default {
    "Module": {
        "Home": "首頁",
        "PerpetualContract": "永續合約",
        "CurrencyTrading": "幣幣交易",
        "SecondContract": "秒合約",
        "Finance": "理財",
        "Announcement": "公告",
        "Assets": "資產",
        "Account": "帳戶"
    },
    "Login": {
        "Login": "登入",
        "Register": "註冊",
        "RetrievePassword": "找回密碼",
        "Type": "電子郵件信箱",
        "Email": "電子郵件信箱",
        "Password": "密碼",
        "ConfirmPassword": "確認密碼",
        "VerificationCode": "驗證碼",
        "Send": "傳送",
        "PhoneNumber": "手機號碼",
        "InvitationCode": "邀請碼",
        "Agree": "我已閱讀並同意",
        "UserAgreement": "使用者協定",
        "EnterEmail": "請輸入你的電子郵件信箱",
        "EnterEmailError": "請輸入正確的電子郵件信箱格式",
        "EnterPassword": "請輸入登入密碼",
        "EnterPasswordError": "密碼格式為6到20個字符",
        "EnterConfirmPassword": "請確認你的密碼",
        "EnterConfirmPasswordError": "兩次輸入密碼不一致",
        "EnterEmailFirst": "請先輸入電子郵件信箱",
        "EnterVerificationCode": "請輸入驗證碼",
        "EnterPhoneNumber": "請輸入你的手機號碼",
        "EnterInvitationCode": "請輸入邀請碼",
        "ConfirmAgreement": "請閱讀並同意用戶協議",
        "Forget": "忘記密碼？"
    },
    "Home": {
        "CustomerService": "客戶服務",
        "TcTitle": "訊息提示",
        "TopTitle1": "快速開始你的加密貨幣投資",
        "TopTitle2": "BitVenus是買賣加密貨幣最值得信賴、最安全的地方",
        "EmailAddress": "電子郵件地址",
        "StartUsing": "開始使用",
        "SeeMore": "看更多",
        "MarketTrend": "市場趨勢",
        "Currency": "幣種",
        "LatestPrice": "最新價格",
        "Change": "24小時改變",
        "TradingVolume": "24小時成交量",
        "Trend": "趨勢",
        "Operate": "操作",
        "Trade": "交易",
        "AllTradingVolume": "交易所24小時交易量",
        "SupportedCountries": "支持的國家",
        "RegisterNumber": "註冊的用戶量",
        "Box4Title": "最值得信賴的加密貨幣平台",
        "Box4Desc": "以下是您應該選擇BitVenus的原因",
        "Box4TabTitles": [
            "多樣化交易",
            "最低手續費",
            "領先的安全",
            "極致使用者體驗"
        ],
        "Box4TabDescs": [
            "BitVenus提供具有頂級流動性的訂單簿，有豐富的加密貨幣交易及資產管理服務，滿足多樣化交易需求。",
            "BitVenus透過提供交易者進入點對點融資市場的途徑，選擇特定加密貨幣現貨交易對，擁有最低的交易手續費。",
            "BitVenus採用離線儲存方式，個人交易資料透過端對端加密獲得保障，平台即時監控，全方位保護用戶資產安全。",
            "使用者至上，簡單易用，聆聽回饋，產品極速優化，7*24小時即時在線， 多語言一對一專業服務。"
        ],
        "Box5Title": "不斷豐富的產品線，滿足各類使用者需求",
        "Box5TabTitles": [
            "永續合約",
            "幣幣交易",
            "秒合約",
            "理財"
        ],
        "Box5TabDescs": [
            "槓桿交易，利用槓桿以自有資金作為保證金，從平台借到N倍於保證金的資金當做本金，進行投資獲得利潤。",
            "以某種數位貨幣為計價單位，購買其他種類的數位貨幣，以最優的價格快速買賣加密貨幣，以獲得最佳收益。",
            "金融衍生性商品，透過分析短期市場行情，交易者在特定時間以預定價格買進或賣出，快頻率進行交易賺取利潤。",
            "具競爭力的高年化報酬率， 一流的資金安全保障，輕鬆賺收益！"
        ],
        "TradeNow": "立即交易",
        "Box6Title1": "隨時隨地，開啟交易",
        "Box6Title2": "APP和WEB，都可以快速開啟的交易",
        "FooterItems1": [
            "條款與政策",
            "費率標準",
            "風險警告",
            "反洗錢",
            "關於我們"
        ],
        "FooterItems2": [
            "服務隱私",
            "服務條款",
            "隱私權聲明",
            "白皮書",
            "MSB認證"
        ],
        "FooterItems3": [
            "幫助中心",
            "新手指引",
            "操作指南",
            "聯絡我們"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "請輸入貨幣名稱",
        "Currency": "幣種",
        "LatestPrice": "最新價格",
        "Change": "改變",
        "Highest": "24小時最高",
        "Lowest": "24小時最低",
        "OrderBook": "買賣盤",
        "LatestRecord": "最新交易",
        "All": "全部",
        "Bids": "買盤",
        "Asks": "賣盤",
        "Price": "價格",
        "Quantity": "數量",
        "Total": "總計",
        "Time": "時間",
        "AveragePrice": "平均價格",
        "EnterPayPass": "請輸入交易密碼",
        "BuyTips": "你確定要買嗎？",
        "type": "類型"
    },
    "PerpetualContract": {
        "OrderType": "訂單類型",
        "Market": "市價",
        "EnterQuantity": "輸入購買數量",
        "HandlingFee": "手續費",
        "AvailableBalance": "可用餘額",
        "Lever": "槓桿",
        "Fee": "費用",
        "OpenLong": "開多",
        "OpenShort": "開空",
        "CurrentOrders": "目前訂單",
        "OrderHistory": "訂單歷史",
        "Margin": "保證金",
        "CurrentPrice": "目前價格",
        "OpeningPrice": "開盤價",
        "UnsettledProfit": "未結算利潤",
        "Operate": "操作",
        "OpenTime": "開放時間",
        "ClosingTime": "關閉時間",
        "ClosingPrice": "收盤價",
        "SettlementProfit": "結算利潤",
        "ClosePosition": "平倉",
        "Edit": "編輯",
        "EditOrder": "編輯訂單",
        "EnterCloseQuantity": "請輸入數量",
        "ConfirmOrder": "確認訂單",
        "TakeProfitPrice": "止盈價",
        "StopPrice": "停損價"
    },
    "CurrencyTrading": {
        "Buy": "買入",
        "Sell": "賣出",
        "MyBalance": "我的餘額",
        "AvailablePurchaseAmount": "可購買金額",
        "ConvertibleAmount": "可兌換金額",
        "CurrentPrice": "目前價格",
        "BuyQuantity": "買入數量",
        "EnterBuyQuantity": "輸入買入的數量",
        "SellQuantity": "賣出數量",
        "EnterSellQuantity": "輸入賣出的數量",
        "RequiredAmount": "所需金額",
        "ExchangeAmount": "兌換金額",
        "OrderHistory": "訂單歷史",
        "Status": "狀態",
        "Success": "成功"
    },
    "SecondContract": {
        "Buy": "買入",
        "OpeningQuantity": "開倉數量",
        "EnterOpeningQuantity": "輸入貨幣數量",
        "SelectQuantity": "選擇數量",
        "OpeningTime": "開倉時間",
        "minute": "分分鐘",
        "Balance": "餘額",
        "Transfer": "轉移",
        "HandlingFee": "手續費",
        "Profitability": "獲利率",
        "BuyUp": "買漲",
        "BuyDown": "買跌",
        "InTransaction": "交易中",
        "Closed": "已關閉",
        "OrderNumber": "訂單編號",
        "CurrentPrice": "目前價格",
        "Direction": "方向",
        "EstimatedProfitLoss": "預估損益",
        "Countdown": "倒數計時",
        "OpeningPrice": "開倉價",
        "ClosingPrice": "平倉價",
        "ProfitAndLoss": "損益",
        "Scheduled": "預定",
        "ChooseTime": "請選擇買入時間",
        "ChooseDuration": "選擇購買時長"
    },
    "Finance": {
        "TopTitle": "理財寶",
        "TopDesc": "是區塊鏈資產持幣生息，穩定收益型投資平台，包括定期（鎖倉理財），活期理財等多種類型的產品，為穩健型投資用戶提供最佳投資管道",
        "AllProducts": "全部產品",
        "InProgress": "進行中",
        "AboutToStart": "即將開始",
        "ItsOver": "結束了",
        "FinancialRecords": "理財記錄",
        "ReferenceAnnualized": "參考年化",
        "day": "天",
        "hour": "小時",
        "minute": "分分鐘",
        "second": "秒",
        "Unlock": "解鎖",
        "RemainingPosition": "剩餘部位",
        "LockedPositions": "已鎖定部位",
        "EndOfDistance": "距離結束",
        "JoinNow": "立即參加",
        "OurAdvantage": "我們的優勢",
        "AdvanListTitles": [
            "高效能效益",
            "週期更短",
            "安全可靠"
        ],
        "AdvanListDescs": [
            "運用多種專業金融工具組合為使用者打造優質的高收益理財產品",
            "週期更靈活，鎖倉短至7天",
            "專業的管理及風控方案為資產收益提供保障"
        ],
        "JoinNum": "{joinNum}人已參與",
        "DailyRate": "日利率",
        "StartingTime": "開始時間",
        "LockPeriod": "鎖定期",
        "RebateMethod": "回饋方式",
        "RebateMethod1": "到期返本",
        "RebateMethod2": "每日回饋",
        "LockPosition": "鎖定部位",
        "ProductSpecification": "產品規格",
        "LockedCurrency": "鎖定貨幣",
        "SubscriptionAmount": "訂閱金額",
        "MinAndMax": "最小/最大",
        "TotalPosition": "總倉位",
        "TradingRules": "交易規則",
        "BuyProductTips": "你確定要訂閱該產品嗎？",
        "Pending": "待辦的",
        "Completed": "已完成",
        "ProductName": "產品名稱",
        "PurchaseAmount": "購買量",
        "CumulativeIncome": "累計收益",
        "EndTime": "結束時間"
    },
    "Assets": {
        "FinancialRecords": "財務記錄",
        "Deposit": "存款",
        "Withdraw": "提現",
        "Transfer": "轉移",
        "TotalBalance": "總餘額",
        "Refresh": "重新整理",
        "Currency": "幣種",
        "AvailableBalance": "可用餘額",
        "Frozen": "凍結的",
        "Operate": "操作",
        "Type": "類型",
        "Time": "時間",
        "Quantity": "數量",
        "SelectCurrency": "選擇幣種",
        "Address": "地址",
        "Notice": "注意",
        "DepositAmount": "存款金額",
        "EnterDepositAmount": "請輸入存款金額",
        "PaymentVoucher": "付款憑證",
        "UploadPaymentVoucher": "請上傳付款憑證",
        "DepositRecord": "存款記錄",
        "OrderNumber": "訂單編號",
        "Status": "狀態",
        "Status1": "進行中",
        "Status2": "已完成",
        "Status3": "失敗",
        "Status4": "例外",
        "Status5": "已取消",
        "WithdrawalAmount": "提現金額",
        "Least": "最小",
        "All": "全部",
        "WithdrawalCurrency": "提現幣種",
        "WithdrawalAddress": "提現地址",
        "HandlingFee": "手續費",
        "ReceiptAmount": "到帳金額",
        "TransactionPassword": "交易密碼",
        "SetTransactionPassword": "設定交易密碼",
        "GoogleVerificationCode": "Google驗證碼",
        "EnterWithdrawalAmount": "請輸入提現金額",
        "EnterWithdrawalAddress": "請輸入提現地址",
        "EnterTransactionPassword": "請輸入交易密碼",
        "EnterGoogleVerificationCode": "請輸入谷歌驗證碼",
        "WithdrawalsRecord": "提現記錄",
        "ExchangeCurrency": "兌換幣種",
        "ExchangeAmount": "兌換金額",
        "ExchangeRate": "匯率",
        "Expected": "預期",
        "TransferRecord": "轉移記錄",
        "CurrencyTransfer": "轉移幣種",
        "Success": "成功",
        "EnterExchangeAmount": "請輸入兌換金額"
    },
    "Account": {
        "Logout": "登出",
        "PersonalCenter": "個人中心",
        "LoginHistory": "登入紀錄",
        "Time": "時間",
        "Account": "帳號",
        "LoginPassword": "登入密碼",
        "Modify": "修改",
        "TransactionPassword": "交易密碼",
        "SetTransactionPassword": "設定交易密碼",
        "ResetTransactionPassword": "重設交易密碼",
        "Recommend": "推薦",
        "MobileAuth": "移動認證",
        "BindMailbox": "綁定信箱",
        "GoogleAuth": "谷歌身份驗證",
        "KYCCertification": "KYC認證",
        "MobileAuthDesc": "用於檢索密碼和修改安全性設定以進行安全性驗證",
        "BindMailboxDesc": "用於檢索密碼和修改安全性設定以進行安全性驗證",
        "GoogleAuthDesc": "用於提取貨幣以進行安全驗證",
        "Add": "添加",
        "Remove": "移除",
        "GoAuth": "去認證",
        "Verified": "已認證",
        "UnderReview": "審核中",
        "ChangeLoginPassword": "修改登入密碼",
        "ChangeLoginPasswordTips": "登入密碼修改後24小時內不能提現,請謹慎操作",
        "OriginalPassword": "輸入原密碼",
        "NewPassword": "輸入新密碼",
        "ConfirmPassword": "再次輸入新密碼",
        "EnterOriginalPassword": "請輸入原密碼",
        "EnterNewPassword": "請輸入新密碼",
        "EnterConfirmPassword": "請再次輸入新密碼",
        "EnterPasswordError": "密碼格式為6到20個字符",
        "EnterConfirmPasswordError": "兩次輸入密碼不一致",
        "ChangeTransactionPasswordTips": "交易密碼修改後24小時內不能提現,請謹慎操作",
        "EnterLoginPassword": "輸入登入密碼",
        "PleaseEnterLoginPassword": "請輸入登入密碼",
        "AddGoogleAuth": "新增Google Authenticator",
        "Step1": "步驟1 下載“Google Authenticator”APP",
        "Step2": "步驟2 在Google Authenticator中新增金鑰並備份",
        "Step3": "步驟3 輸入來自Google Validator的6位驗證碼",
        "Step1Desc": "iOS用戶登入App Store,搜尋「Authenticator」進行下載<br/><br/>安卓用戶登入應用程式商店或使用手機瀏覽器搜尋「Google Authenticator」進行下載",
        "Step2Desc": "開啟Google Authenticator,掃描下方二維碼或手動輸入下方金鑰,新增驗證令牌",
        "CopyKey": "複製密鑰",
        "EnterGoogleVerificationCode": "輸入谷歌驗證碼",
        "PleaseEnterGoogleVerificationCode": "請輸入谷歌驗證碼",
        "RemoveGoogleAuth": "刪除Google Validator",
        "RemoveGoogleAuthTips": "刪除Google Validator後24小時內不能提現,請謹慎操作",
        "UploadIDPhoto": "上傳身分證照片",
        "UploadIDPhotoTips": "上傳整個文件的彩色影像。不允許截圖。圖片大小不能超過10M,只能是JPG或PNG格式。",
        "FrontPhoto": "身分證正面照片",
        "BackPhoto": "身分證反面照片",
        "FrontPhotoTips": "點擊上傳身分證正面照片",
        "BackPhotoTips": "點擊上傳身分證反面照片"
    },
    "FundingType": {
        "All": "全部",
        "system": "系統操作",
        "commission": "佣金",
        "recharge": "儲值",
        "withdraw": "提現",
        "withdrawFail": "提現失敗",
        "second_order": "秒合約下單",
        "secondOrderWin": "秒合約贏單",
        "cancelSOrder": "取消秒合約訂單",
        "perpetual_order": "永續合約下單保證金+手續費",
        "perpeTualOrderClo": "永續合約閉倉",
        "trade": "幣幣交易",
        "buyFinance": "購買理財",
        "financeProfit": "理財返利",
        "financeMoney": "理財返還本金"
    },
    "Common": {
        "Language": "語言",
        "Tips": "提示",
        "Confirm": "確認",
        "Cancel": "取消",
        "Submit": "提交",
        "copy": "複製",
        "CopySuccessfully": "複製成功",
        "NoData": "沒有數據",
        "Loading": "等待中...",
        "Back": "返回",
        "Details": "詳情"
    }
}
