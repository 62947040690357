<template>
    <div id="app">
        <BitHeader ref="bitHeader" />
        <router-view />
        <BitFooter />
    </div>
</template>

<script>
    export default {
        components: {
            BitHeader: () => import('@/components/BitHeader'),
            BitFooter: () => import('@/components/BitFooter')
        },
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss">
    html,
    body {
        padding: 0;
        margin: 0;
    }
    
    .input-item {
        outline: none;
    }
    
    #app {
        width: 100%;
        min-width: $pc-min-width;
        max-width: $pc-max-width;
        min-height: 100vh;
        margin: 0 auto;
        font-family: Arial;
        background-color: #FFFFFF;
    }
    
    .pc-container {
        min-height: $container-height;
    }
    
    .download-popover {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 0 !important;
        
        .app-link {
            max-width: 100px;
            margin-top: 8px;
            font-size: 14px;
            color: #333333;
            word-break: break-all;
        }
    }
    
    .el-tooltip__popper {
        padding: 16px !important;
    }
    
    .cal-list {
        width: 160px;
        
        .cal-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 13px;
            
            &:not(:last-child) {
                margin-bottom: 8px;
            }
            
            .label {
                max-width: 48%;
                color: #A2A5AE;
                word-wrap: break-word;
            }
            
            .value {
                max-width: 48%;
                color: #FFFFFF;
                word-break: break-all;
            }
        }
    }
    
    .popover-title-box {
        width: 100%;
        box-sizing: border-box;
        padding: 12px 0;
        
        .popover-title {
            width: 100%;
            font-size: 15px;
            color: #ff5429;
            text-align: center;
            word-wrap: break-word;
            cursor: pointer;
        }
    }
    
    .prompt-box {
        
        .el-input__inner {
            height: 45px;
            line-height: 45px;
            padding: 0 16px;
            border: 1px solid #D3D3D3;
            font-size: 15px;
            color: #000000;
            background-color: #FFFFFF;
            
            &:focus {
                border-color: #D3D3D3;
            }
        }
        
        .prompt-confirm-btn {
            border-color: #F08834 !important;
            background-color: #F08834 !important;
            
            &:hover {
                border-color: rgba(240,136,52,.9) !important;
                background-color: rgba(240,136,52,.9) !important;
            }
        }
        
        .prompt-cancel-btn {
            
            &:hover {
                color: #F08834;
                border-color: #F08834;
                background-color: #F3F5FF;
            }
        }
    }
    
    .close-dialog {
        .el-dialog__close:hover {
            color: #F08834 !important;
        }
        
        .el-dialog__body {
            padding: 25px 20px 30px !important;
        }
        
        .el-dialog__footer {
            padding: 10px 20px 30px !important;
        }
        
        .input-box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 45px;
            line-height: 45px;
            box-sizing: border-box;
            padding: 0 16px;
            border: 1px solid #D3D3D3;
            border-radius: 4px;
            background-color: #FFFFFF;
            
            .el-input__inner {
                width: 100%;
                height: 42px;
                line-height: 42px;
                padding: 0;
                border: 0;
                font-size: 14px;
                color: #000000;
            }
            
            .all {
                margin-left: 16px;
                font-size: 14px;
                color: #F08834;
                white-space: nowrap;
                cursor: pointer;
            }
        }
        
        .el-button {
            width: 100%;
            padding: 16px 20px;
            font-size: 15px;
            border-color: #F08834;
            background-color: #F08834;
            
            &:hover {
                border-color: rgba(84,102,239,.9);
                background-color: rgba(84,102,239,.9);
            }
        }
    }
</style>
