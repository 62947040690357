import { ws_url } from '@/request/api.js'
import store from '@/store/index.js'

export default class Socket {
    init() {
        if(localStorage.getItem('token')) {
            let url = ws_url + '?token=' + localStorage.getItem('token') // websocket 连接地址
            this.socket = new WebSocket(url);
            this.socket.addEventListener('open', (evt) => this.open(evt))
            this.socket.addEventListener('message', (evt) => this.message(evt))
            this.socket.addEventListener('close', (evt) => this.close(evt))
        }
    }
    open() {
        // if(this.socket.readyState === 1)
        store.commit('changeConnect', {
            status: true
        })
        clearInterval(this.timer)
        this.timer = setInterval(() => {
            this.socket.send('{"type":"ping"}')
        },30000)
    }
    sendMsg(msg) {
        if(this.socket.readyState == 1) {
            this.socket.send(msg)
        } else {
            setTimeout(() => {
                this.sendMsg(msg)
            },1000)
        }
    }
    message(evt) {
        let { data } = evt;
        if(data !== '') {
        	let res = JSON.parse(data)
            let channel_type = res.channel ? res.channel.split(':')[0] : ''
        	switch (channel_type) {
        		case 'ticker':
                    store.commit('changeProduct', res.data)
        			break
                case 'depth':
                    store.commit('setOrderBook', res.data)
                	break
                case 'trade':
                    store.commit('setLatestRecord', res.data)
                	break
                case 'perpetualOrder':
                    store.commit('setPerpetualOrder', res.data)
                	break
                case 'kline':
                    let symbol = res.channel.split(':')[1]
                    let resolution = res.channel.split(':')[2]
                    store.commit('setKline', { data: res.data, symbol, resolution })
                	break
        	}
        }
    }
    close() {
        store.commit('changeConnect', {
            type: 'next',
            status: false
        })
        this.init()
    }
}

