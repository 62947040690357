export default {
    "Module": {
        "Home": "Trang chủ",
        "PerpetualContract": "Hợp đồng tương lai vĩnh viễn",
        "CurrencyTrading": "Giao dịch tiền tệ",
        "SecondContract": "Hợp đồng quyền chọn",
        "Finance": "Tài chính",
        "Announcement": "Thông báo",
        "Assets": "Tài sản",
        "Account": "Tài khoản"
    },
    "Login": {
        "Login": "Đăng nhập",
        "Register": "Đăng ký",
        "RetrievePassword": "Lấy lại mật khẩu",
        "Type": "E-mail",
        "Email": "E-mail",
        "Password": "Mật khẩu",
        "ConfirmPassword": "Xác nhận mật khẩu",
        "VerificationCode": "Mã xác nhận",
        "Send": "Gửi",
        "PhoneNumber": "Số điện thoại",
        "InvitationCode": "Mã mời",
        "Agree": "tôi đã đọc và đồng ý",
        "UserAgreement": "sự thỏa thuận của người dùng",
        "EnterEmail": "Vui lòng nhập email",
        "EnterEmailError": "Vui lòng nhập đúng định dạng email",
        "EnterPassword": "Vui lòng nhập mật khẩu của bạn",
        "EnterPasswordError": "Định dạng mật khẩu là 6 đến 20 ký tự",
        "EnterConfirmPassword": "Vui lòng xác nhận mật khẩu của bạn",
        "EnterConfirmPasswordError": "Mật khẩu nhập hai lần không nhất quán",
        "EnterEmailFirst": "Vui lòng nhập email của bạn trước",
        "EnterVerificationCode": "vui lòng nhập mã xác nhận",
        "EnterPhoneNumber": "Vui lòng nhập số điện thoại di động của bạn",
        "EnterInvitationCode": "Vui lòng nhập mã mời",
        "ConfirmAgreement": "Vui lòng đọc và đồng ý với Thỏa thuận người dùng",
        "Forget": "Quên mật khẩu?"
    },
    "Home": {
        "CustomerService": "Dịch vụ khách hàng",
        "TcTitle": "Tuyên bố",
        "TopTitle1": "Bắt đầu đầu tư tiền điện tử của bạn một cách nhanh chóng",
        "TopTitle2": "BitVenus là nơi đáng tin cậy và an toàn nhất để mua và bán tiền điện tử",
        "EmailAddress": "Địa chỉ email",
        "StartUsing": "Bắt đầu sử dụng",
        "SeeMore": "Xem thêm",
        "MarketTrend": "Xu hướng thị trường",
        "Currency": "Tiền tệ",
        "LatestPrice": "Giá mới nhất",
        "Change": "Thay đổi 24H",
        "TradingVolume": "Khối lượng giao dịch 24H",
        "Trend": "Xu hướng",
        "Operate": "Vận hành",
        "Trade": "Buôn bán",
        "AllTradingVolume": "Khối lượng giao dịch Exchange 24H",
        "SupportedCountries": "Các quốc gia được hỗ trợ",
        "RegisterNumber": "Số lượng người dùng đã đăng ký",
        "Box4Title": "Nền tảng tiền điện tử đáng tin cậy nhất",
        "Box4Desc": "Dưới đây là những lý do tại sao bạn nên chọn BitVenus",
        "Box4TabTitles": [
            "Giao dịch đa dạng",
            "Phí tối thiểu",
            "An ninh hàng đầu",
            "Trải nghiệm người dùng tối ưu"
        ],
        "Box4TabDescs": [
            "BitVenus cung cấp sổ đặt hàng với tính thanh khoản hàng đầu, dịch vụ quản lý tài sản và giao dịch tiền điện tử phong phú để đáp ứng nhu cầu giao dịch đa dạng.",
            "Bằng cách cung cấp cho các nhà giao dịch quyền truy cập vào thị trường tài chính ngang hàng, BitVenus chọn các cặp giao dịch giao ngay tiền điện tử cụ thể với phí giao dịch thấp nhất.",
            "BitVenus áp dụng phương thức lưu trữ ngoại tuyến và dữ liệu giao dịch cá nhân được đảm bảo thông qua mã hóa đầu cuối và nền tảng giám sát theo thời gian thực để bảo vệ hoàn toàn tính bảo mật cho tài sản của người dùng.",
            "Người dùng đầu tiên, dễ sử dụng, lắng nghe phản hồi, tối ưu hóa sản phẩm nhanh chóng, trực tuyến 7 * 24 giờ theo thời gian thực, dịch vụ chuyên nghiệp đa ngôn ngữ."
        ],
        "Box5Title": "Không ngừng làm phong phú các dòng sản phẩm nhằm đáp ứng nhu cầu đa dạng của người dùng",
        "Box5TabTitles": [
            "Hợp đồng tương lai vĩnh viễn",
            "Giao dịch tiền tệ",
            "Hợp đồng quyền chọn",
            "Tài chính"
        ],
        "Box5TabDescs": [
            "Tận dụng giao dịch, sử dụng đòn bẩy để sử dụng tiền của chính mình làm tiền ký quỹ, vay N lần số tiền từ nền tảng làm vốn gốc và đầu tư để thu lợi nhuận.",
            "Sử dụng một loại tiền kỹ thuật số nhất định làm đơn vị định giá, mua các loại tiền kỹ thuật số khác, nhanh chóng mua và bán tiền điện tử ở mức giá tốt nhất và thu được lợi nhuận tốt nhất.",
            "Các công cụ phái sinh tài chính, bằng cách phân tích các điều kiện thị trường ngắn hạn, nhà giao dịch mua hoặc bán ở một mức giá định trước tại một thời điểm cụ thể và giao dịch với tần suất nhanh để kiếm lợi nhuận.",
            "Tỷ suất lợi nhuận hàng năm cao cạnh tranh, đảm bảo an toàn vốn hạng nhất, dễ kiếm thu nhập!"
        ],
        "TradeNow": "Giao dịch ngay bây giờ",
        "Box6Title1": "Bắt đầu giao dịch mọi lúc, mọi nơi",
        "Box6Title2": "Cả APP và WEB đều có thể mở giao dịch nhanh chóng",
        "FooterItems1": [
            "Điều khoản và chính sách",
            "Tiêu chuẩn đánh giá",
            "Cảnh báo rủi ro",
            "Chống rửa tiền",
            "Về chúng tôi"
        ],
        "FooterItems2": [
            "Quyền riêng tư của dịch vụ",
            "Điều khoản dịch vụ",
            "Cam kết bảo mật",
            "giấy trắng",
            "Chứng nhận MSB"
        ],
        "FooterItems3": [
            "Trung tâm trợ giúp",
            "Hướng dẫn cho người mới bắt đầu",
            "Hướng dẫn vận hành",
            "Liên hệ chúng tôi"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "Vui lòng nhập tên tiền tệ",
        "Currency": "Tiền tệ",
        "LatestPrice": "Giá mới nhất",
        "Change": "Thay đổi",
        "Highest": "Cao 24H",
        "Lowest": "Thấp 24H",
        "OrderBook": "Quyển sổ đặc Mua hàng",
        "LatestRecord": "Kỷ lục mới nhất",
        "All": "Tất cả",
        "Bids": "Giá thầu",
        "Asks": "Hỏi",
        "Price": "Giá",
        "Quantity": "Số lượng",
        "Total": "Tổng cộng",
        "Time": "Thời gian",
        "AveragePrice": "Giá trung bình",
        "EnterPayPass": "Vui lòng nhập mật khẩu giao dịch",
        "BuyTips": "Bạn có chắc chắn muốn mua không?",
        "type": "Kiểu"
    },
    "PerpetualContract": {
        "OrderType": "Kiểu đơn hàng",
        "Market": "Chợ",
        "EnterQuantity": "Nhập số lượng mua hàng",
        "HandlingFee": "Phí xử lý",
        "AvailableBalance": "Số dư khả dụng",
        "Lever": "đòn bẩy",
        "Fee": "Phí",
        "OpenLong": "Mở lâu",
        "OpenShort": "Mở ngắn",
        "CurrentOrders": "Thứ tự hiện tại",
        "OrderHistory": "Lịch sử đơn hàng",
        "Margin": "Lề",
        "CurrentPrice": "Giá hiện tại",
        "OpeningPrice": "Giá mở cửa",
        "UnsettledProfit": "Lợi nhuận chưa quyết toán",
        "Operate": "Vận hành",
        "OpenTime": "Thời gian mở cửa",
        "ClosingTime": "Thời gian đóng cửa",
        "ClosingPrice": "Giá đóng cửa",
        "SettlementProfit": "Lợi nhuận quyết toán",
        "ClosePosition": "Đóng vị thế",
        "Edit": "Biên tập",
        "EditOrder": "Chỉnh sửa đơn hàng",
        "EnterCloseQuantity": "Vui lòng nhập số lượng",
        "ConfirmOrder": "Xác nhận đơn hàng",
        "TakeProfitPrice": "Lấy giá lãi",
        "StopPrice": "Dừng giá"
    },
    "CurrencyTrading": {
        "Buy": "Mua",
        "Sell": "Bán",
        "MyBalance": "Số dư của tôi",
        "AvailablePurchaseAmount": "Số tiền sẵn có để mua",
        "ConvertibleAmount": "Số tiền có thể chuyển đổi",
        "CurrentPrice": "Giá hiện tại",
        "BuyQuantity": "Mua số lượng",
        "EnterBuyQuantity": "Nhập số lượng cần mua",
        "SellQuantity": "Bán số lượng",
        "EnterSellQuantity": "Nhập số lượng cần bán",
        "RequiredAmount": "Khối lượng bắt buộc",
        "ExchangeAmount": "Số tiền trao đổi",
        "OrderHistory": "Lịch sử đơn hàng",
        "Status": "Trạng thái",
        "Success": "Thành công"
    },
    "SecondContract": {
        "Buy": "Mua",
        "OpeningQuantity": "Số lượng mở",
        "EnterOpeningQuantity": "Nhập số tiền tệ",
        "SelectQuantity": "Chọn số lượng",
        "OpeningTime": "Thời gian mở cửa",
        "minute": "phút",
        "Balance": "Sự cân bằng",
        "Transfer": "Chuyển khoản",
        "HandlingFee": "Phí xử lý",
        "Profitability": "Khả năng sinh lời",
        "BuyUp": "mua lên",
        "BuyDown": "Mua xuống",
        "InTransaction": "Trong giao dịch",
        "Closed": "Đã đóng",
        "OrderNumber": "Số đơn hàng",
        "CurrentPrice": "Giá hiện tại",
        "Direction": "Phương hướng",
        "EstimatedProfitLoss": "Ước tính lãi lỗ",
        "Countdown": "Đếm ngược",
        "OpeningPrice": "Giá mở cửa",
        "ClosingPrice": "Giá đóng cửa",
        "ProfitAndLoss": "Lợi nhuận và thua lỗ",
        "Scheduled": "Lên kế hoạch",
        "ChooseTime": "Vui lòng chọn thời điểm mua",
        "ChooseDuration": "Chọn thời hạn mua hàng"
    },
    "Finance": {
        "TopTitle": "Kho báu tài chính",
        "TopDesc": "Đây là tài sản blockchain nắm giữ tiền tệ để kiếm lãi, nền tảng đầu tư thu nhập ổn định, bao gồm cố định (quản lý tài chính cố định), quản lý tài chính hiện tại và các loại sản phẩm khác, cung cấp kênh đầu tư tốt nhất cho người dùng đầu tư ổn định",
        "AllProducts": "Tất cả sản phẩm",
        "InProgress": "Trong tiến trình",
        "AboutToStart": "Về để bắt đầu",
        "ItsOver": "đã hết",
        "FinancialRecords": "Các báo cáo tài chính",
        "ReferenceAnnualized": "Tham chiếu hàng năm",
        "day": "ngày",
        "hour": "giờ",
        "minute": "phút",
        "second": "thứ hai",
        "Unlock": "Mở khóa",
        "RemainingPosition": "Vị trí còn lại",
        "LockedPositions": "Vị trí bị khóa",
        "EndOfDistance": "Hết khoảng cách",
        "JoinNow": "Tham gia ngay",
        "OurAdvantage": "lợi thế của chúng tôi",
        "AdvanListTitles": [
            "Thu nhập hiệu quả",
            "Chu kỳ ngắn hơn",
            "An toàn và đáng tin cậy"
        ],
        "AdvanListDescs": [
            "Sử dụng nhiều tổ hợp công cụ tài chính chuyên nghiệp để tạo ra các sản phẩm tài chính chất lượng cao, lợi nhuận cao cho người dùng",
            "Chu kỳ linh hoạt hơn và thời gian khóa chỉ là 7 ngày",
            "Các giải pháp quản lý và kiểm soát rủi ro chuyên nghiệp mang lại sự bảo vệ cho lợi nhuận tài sản"
        ],
        "JoinNum": "{joinNum} người đã tham gia",
        "DailyRate": "Tỷ lệ hàng ngày",
        "StartingTime": "Thời gian bắt đầu",
        "LockPeriod": "Thời gian khóa",
        "RebateMethod": "Phương thức giảm giá",
        "RebateMethod1": "Tiền lãi gốc khi đáo hạn",
        "RebateMethod2": "Giảm giá hàng ngày",
        "LockPosition": "Vị trí khóa",
        "ProductSpecification": "Đặc điểm kỹ thuật sản phẩm",
        "LockedCurrency": "Tiền tệ bị khóa",
        "SubscriptionAmount": "Số tiền đăng ký",
        "MinAndMax": "Tối thiểu/Tối đa",
        "TotalPosition": "Tổng vị thế",
        "TradingRules": "Quy tắc giao dịch",
        "BuyProductTips": "Bạn có chắc chắn muốn đăng ký sản phẩm này không?",
        "Pending": "Chưa giải quyết",
        "Completed": "Hoàn thành",
        "ProductName": "Tên sản phẩm",
        "PurchaseAmount": "số tiền mua",
        "CumulativeIncome": "Thu nhập tích lũy",
        "EndTime": "Thời gian kết thúc"
    },
    "Assets": {
        "FinancialRecords": "Các báo cáo tài chính",
        "Deposit": "Tiền gửi",
        "Withdraw": "Rút",
        "Transfer": "Chuyển khoản",
        "TotalBalance": "Tổng số dư",
        "Refresh": "Làm cho khỏe lại",
        "Currency": "Tiền tệ",
        "AvailableBalance": "Số dư khả dụng",
        "Frozen": "Đông cứng",
        "Operate": "Vận hành",
        "Type": "Kiểu",
        "Time": "Thời gian",
        "Quantity": "Số lượng",
        "SelectCurrency": "Chọn loại tiền tệ",
        "Address": "Địa chỉ",
        "Notice": "Để ý",
        "DepositAmount": "Số tiền gửi",
        "EnterDepositAmount": "Vui lòng nhập số tiền gửi",
        "PaymentVoucher": "Chứng từ thanh toán",
        "UploadPaymentVoucher": "Vui lòng tải lên chứng từ thanh toán",
        "DepositRecord": "Hồ sơ gửi tiền",
        "OrderNumber": "Số đơn hàng",
        "Status": "Trạng thái",
        "Status1": "Trong tiến trình",
        "Status2": "Hoàn thành",
        "Status3": "Thất bại",
        "Status4": "Bất thường",
        "Status5": "Đã hủy",
        "WithdrawalAmount": "Số tiền rút",
        "Least": "Ít nhất",
        "All": "Tất cả",
        "WithdrawalCurrency": "Loại tiền rút",
        "WithdrawalAddress": "Địa chỉ rút tiền",
        "HandlingFee": "Phí xử lý",
        "ReceiptAmount": "Số tiền nhận",
        "TransactionPassword": "Mật khẩu giao dịch",
        "SetTransactionPassword": "Đặt mật khẩu giao dịch",
        "GoogleVerificationCode": "Mã xác minh của Google",
        "EnterWithdrawalAmount": "Vui lòng nhập số tiền rút",
        "EnterWithdrawalAddress": "Vui lòng nhập địa chỉ rút tiền",
        "EnterTransactionPassword": "Vui lòng nhập mật khẩu giao dịch",
        "EnterGoogleVerificationCode": "Vui lòng nhập mã xác minh Google",
        "WithdrawalsRecord": "Hồ sơ rút tiền",
        "ExchangeCurrency": "Trao đổi tiền tệ",
        "ExchangeAmount": "Số tiền trao đổi",
        "ExchangeRate": "Tỷ giá",
        "Expected": "Hy vọng",
        "TransferRecord": "Hồ sơ chuyển nhượng",
        "CurrencyTransfer": "Loại tiền cần chuyển",
        "Success": "Thành công",
        "EnterExchangeAmount": "Vui lòng nhập số tiền quy đổi"
    },
    "Account": {
        "Logout": "Đăng xuất",
        "PersonalCenter": "Trung tâm cá nhân",
        "LoginHistory": "Lịch sử đăng nhập",
        "Time": "Thời gian",
        "Account": "Tài khoản",
        "LoginPassword": "Mật khẩu đăng nhập",
        "Modify": "Biến đổi",
        "TransactionPassword": "Mật khẩu giao dịch",
        "SetTransactionPassword": "Đặt mật khẩu giao dịch",
        "ResetTransactionPassword": "Đặt lại mật khẩu giao dịch",
        "Recommend": "Gợi ý",
        "MobileAuth": "Xác thực di động",
        "BindMailbox": "Ràng buộc hộp thư",
        "GoogleAuth": "xác thực của Google",
        "KYCCertification": "Chứng nhận KYC",
        "MobileAuthDesc": "Được sử dụng để lấy lại mật khẩu và sửa đổi cài đặt bảo mật để xác minh bảo mật",
        "BindMailboxDesc": "Được sử dụng để lấy lại mật khẩu và sửa đổi cài đặt bảo mật để xác minh bảo mật",
        "GoogleAuthDesc": "Dùng để rút tiền để xác minh bảo mật",
        "Add": "Thêm vào",
        "Remove": "Di dời",
        "GoAuth": "Đi đến xác thực",
        "Verified": "Đã xác minh",
        "UnderReview": "Đang xem xét",
        "ChangeLoginPassword": "Thay đổi mật khẩu đăng nhập",
        "ChangeLoginPasswordTips": "Bạn không thể rút tiền mặt trong vòng 24 giờ sau khi thay đổi mật khẩu đăng nhập, vui lòng thận trọng khi thao tác",
        "OriginalPassword": "Nhập mật khẩu ban đầu",
        "NewPassword": "Nhập mật khẩu mới",
        "ConfirmPassword": "Nhập lại mật khẩu mới",
        "EnterOriginalPassword": "Vui lòng nhập mật khẩu ban đầu",
        "EnterNewPassword": "Vui lòng nhập mật khẩu mới",
        "EnterConfirmPassword": "Vui lòng nhập lại mật khẩu mới",
        "EnterPasswordError": "Định dạng mật khẩu là 6 đến 20 ký tự",
        "EnterConfirmPasswordError": "Mật khẩu nhập hai lần không nhất quán",
        "ChangeTransactionPasswordTips": "Bạn không thể rút tiền mặt trong vòng 24 giờ sau khi thay đổi mật khẩu giao dịch, vui lòng thận trọng khi thao tác",
        "EnterLoginPassword": "Nhập mật khẩu đăng nhập",
        "PleaseEnterLoginPassword": "Vui lòng nhập mật khẩu của bạn",
        "AddGoogleAuth": "Thêm Trình xác thực Google",
        "Step1": "Bước 1 Tải xuống ứng dụng Google Authenticator",
        "Step2": "Bước 2 Thêm khóa vào Google Authenticator và sao lưu",
        "Step3": "Bước 3 Nhập mã xác minh gồm 6 chữ số từ Google Validator",
        "Step1Desc": "Người dùng iOS đăng nhập App Store, tìm kiếm Authenticator để tải về<br/><br/> Người dùng Android đăng nhập kho ứng dụng hoặc dùng trình duyệt trên di động tìm kiếm Google Authenticator để tải về",
        "Step2Desc": "Mở Google Authenticator, quét mã QR bên dưới hoặc nhập thủ công key bên dưới để thêm mã thông báo xác minh",
        "CopyKey": "Sao chép khóa",
        "EnterGoogleVerificationCode": "Nhập Mã xác minh của Google",
        "PleaseEnterGoogleVerificationCode": "Vui lòng nhập mã xác minh Google",
        "RemoveGoogleAuth": "Xóa Trình xác thực Google",
        "RemoveGoogleAuthTips": "Bạn không thể rút tiền mặt trong vòng 24 giờ sau khi xóa Google Validator, vui lòng vận hành thận trọng",
        "UploadIDPhoto": "Tải ảnh CMND lên",
        "UploadIDPhotoTips": "Tải lên hình ảnh màu của toàn bộ tài liệu. Ảnh chụp màn hình không được phép. Kích thước của hình ảnh không được vượt quá 10M và chỉ có thể ở định dạng JPG hoặc PNG.",
        "FrontPhoto": "Ảnh mặt trước CMND",
        "BackPhoto": "Ảnh mặt sau của CMND",
        "FrontPhotoTips": "Click để upload ảnh mặt trước CMND",
        "BackPhotoTips": "Nhấp để tải lên ảnh mặt sau CMND của bạn"
    },
    "FundingType": {
        "All": "Tất cả",
        "system": "Vận hành hệ thống",
        "commission": "Nhiệm vụ",
        "recharge": "Nạp điện",
        "withdraw": "Rút",
        "withdrawFail": "Rút tiền không thành công",
        "second_order": "Lệnh hợp đồng quyền chọn",
        "secondOrderWin": "Hợp đồng quyền chọn giành chiến thắng",
        "cancelSOrder": "Hủy lệnh hợp đồng quyền chọn",
        "perpetual_order": "Tiền đặt cọc hợp đồng tương lai vĩnh viễn + phí xử lý",
        "perpeTualOrderClo": "Hợp đồng tương lai vĩnh viễn",
        "trade": "Giao dịch tiền tệ",
        "buyFinance": "Mua tài chính",
        "financeProfit": "Giảm giá tài chính",
        "financeMoney": "Hoàn trả vốn gốc"
    },
    "Common": {
        "Language": "Ngôn ngữ",
        "Tips": "Lời khuyên",
        "Confirm": "Xác nhận",
        "Cancel": "Hủy bỏ",
        "Submit": "Nộp",
        "copy": "Sao chép",
        "CopySuccessfully": "Sao chép thành công",
        "NoData": "Không có dữ liệu",
        "Loading": "Chờ...",
        "Back": "Mặt sau",
        "Details": "Chi tiết"
    }
}
