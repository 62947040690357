import Vue from 'vue'
import VueRouter from 'vue-router'
import PCLayout from "@/layout/PCLayout"
import H5Layout from "@/layout/H5Layout"

Vue.use(VueRouter)

const routes = [
    { 
        path: '*',
        redirect: '/404'
    },
    {
        path: '/404',
        name: '404 Not Found',
        component: () => import('../views/404.vue')
    },
    {
        path: '/',
        component: PCLayout,
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('../views/home.vue')
            },
            {
                path: 'article',
                name: 'article',
                component: () => import('../views/article.vue')
            },
            {
                path: 'ServiceTerms',
                name: 'fwtk',
                component: () => import('../views/copywriting/fwtk.vue')
            },
            {
                path: 'PrivacyStatement',
                name: 'yssm',
                component: () => import('../views/copywriting/yssm.vue')
            },
            {
                path: 'PerpetualContract',
                name: 'Perpetual contract',
                component: () => import('../views/PerpetualContract')
            },
            {
                path: 'CurrencyTrading',
                name: 'Currency trading',
                component: () => import('../views/CurrencyTrading')
            },
            {
                path: 'SecondContract',
                name: 'Second contract',
                component: () => import('../views/SecondContract')
            },
            {
                path: 'crypto',
                name: 'Buy crypto',
                component: () => import('../views/crypto')
            },
            {
                path: 'finance',
                name: 'finance',
                component: () => import('../views/finance')
            },
            {
                path: 'finance/details',
                name: 'finance details',
                component: () => import('../views/finance/details')
            },
            {
                path: 'finance/record',
                name: 'finance record',
                component: () => import('../views/finance/record')
            },
            {
                path: 'announcement',
                name: 'announcement',
                component: () => import('../views/announcement')
            },
            {
                path: 'login',
                name: 'login',
                component: () => import('../views/login')
            },
            {
                path: 'register',
                name: 'register',
                component: () => import('../views/login/register')
            },
            {
                path: 'forget',
                name: 'forget',
                component: () => import('../views/login/forget')
            },
            {
                path: 'assets',
                name: 'assets',
                component: () => import('../views/assets')
            },
            {
                path: 'assets/deposit',
                name: 'deposit',
                component: () => import('../views/assets/deposit')
            },
            {
                path: 'assets/withdraw',
                name: 'withdraw',
                component: () => import('../views/assets/withdraw')
            },
            {
                path: 'assets/transfer',
                name: 'transfer',
                component: () => import('../views/assets/transfer')
            },
            {
                path: 'account',
                name: 'account',
                component: () => import('../views/account')
            },
            {
                path: 'account/LoginHistory',
                name: 'Login history',
                component: () => import('../views/account/LoginHistory')
            },
            {
                path: 'account/ChangeLoginPassword',
                name: 'Change login password',
                component: () => import('../views/account/ChangeLoginPassword')
            },
            {
                path: 'account/ChangeTransactionPassword/:type',
                name: 'Change transaction password',
                component: () => import('../views/account/ChangeTransactionPassword')
            },
            {
                path: 'account/GoogleAuth/:type',
                name: 'Google auth',
                component: () => import('../views/account/GoogleAuth')
            },
            {
                path: 'account/KYCAuth',
                name: 'KYC auth',
                component: () => import('../views/account/KYCAuth')
            }
        ]
    },
    {
        path: '/h5',
        component: H5Layout,
        children: [
            {
                path: '',
                name: '',
                component: () => import('../h5/home.vue')
            },
			{
			    path: 'article',
			    name: '',
			    component: () => import('../h5/article.vue')
			},
            {
                path: 'CustomerService',
                name: '',
                component: () => import('../h5/CustomerService.vue')
            },
            {
                path: 'PerpetualContract',
                name: '',
                component: () => import('../h5/PerpetualContract')
            },
            {
                path: 'CurrencyTrading',
                name: '',
                component: () => import('../h5/CurrencyTrading')
            },
            {
                path: 'SecondContract',
                name: '',
                component: () => import('../h5/SecondContract')
            },
            {
                path: 'crypto',
                name: '',
                component: () => import('../h5/crypto')
            },
            {
                path: 'finance',
                name: '',
                component: () => import('../h5/finance')
            },
            {
                path: 'finance/details',
                name: '',
                component: () => import('../h5/finance/details')
            },
            {
                path: 'finance/record',
                name: '',
                component: () => import('../h5/finance/record')
            },
            {
                path: 'announcement',
                name: '',
                component: () => import('../h5/announcement')
            },
            {
                path: 'announcement/details',
                name: '',
                component: () => import('../h5/announcement/details')
            },
            {
                path: 'login',
                name: '',
                component: () => import('../h5/login')
            },
            {
                path: 'register',
                name: '',
                component: () => import('../h5/login/register')
            },
            {
                path: 'forget',
                name: '',
                component: () => import('../h5/login/forget')
            },
            {
                path: 'assets',
                name: '',
                component: () => import('../h5/assets')
            },
            {
                path: 'assets/deposit',
                name: '',
                component: () => import('../h5/assets/deposit')
            },
            {
                path: 'assets/withdraw',
                name: '',
                component: () => import('../h5/assets/withdraw')
            },
            {
                path: 'assets/transfer',
                name: '',
                component: () => import('../h5/assets/transfer')
            },
            {
                path: 'account',
                name: '',
                component: () => import('../h5/account')
            },
            {
                path: 'account/LoginHistory',
                name: '',
                component: () => import('../h5/account/LoginHistory')
            },
            {
                path: 'account/ChangeLoginPassword',
                name: '',
                component: () => import('../h5/account/ChangeLoginPassword')
            },
            {
                path: 'account/ChangeTransactionPassword/:type',
                name: '',
                component: () => import('../h5/account/ChangeTransactionPassword')
            },
            {
                path: 'account/GoogleAuth/:type',
                name: '',
                component: () => import('../h5/account/GoogleAuth')
            },
            {
                path: 'account/KYCAuth',
                name: '',
                component: () => import('../h5/account/KYCAuth')
            }
        ]
    }
]

// 解决冗余导航
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // console.log(to.path)
    if(to.path != '/') {
        // chrome
        document.body.scrollTop = 0
        // firefox
        document.documentElement.scrollTop = 0
        // safari
        window.pageYOffset = 0
    }
    next()
})

export default router
