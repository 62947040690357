import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
        localTime: '',
		token: localStorage.getItem('token') || null,
        once: false,
        connect: '{"type":"first","status":false}',
        productList: [],
        curCoin: localStorage.getItem('curCoin') ? JSON.parse(localStorage.getItem('curCoin')) : {
            id: '',
            key: '',
            kline: [],
            name: '',
            pic: '',
            price: {
                close: 0,
                high: 0,
                low: 0,
                number: 0,
                open: 0,
                total: 0
            },
            priceDecimal: 2,
            numberDecimal: 4,
            unit: ''
        },
        tabsValue: 0,
        orderBook: {
            bids: [],
            asks: []
        },
        latestRecord: [],
        perpetualOrder: [],
        curTime: '1min',
        rechargeCoins: [
            'BTC',
            'ETH',
            'USDT',
            'USDC'
        ],
        withdrawCoins: [
            'BTC',
            'ETH',
            'USDT',
            'USDC'
        ],
        transferCoins: [
            'USDT',
            'BTC',
            'ETH',
            'USDC'
        ]
	},

	mutations: {
        setLocalTime(state, time) {
        	state.localTime = new Date(time).format("yyyy-MM-dd hh:mm:ss")
        },
        
        setToken(state, token) {
        	state.token = token
            localStorage.setItem('token',token)
        },
        
        setOnce(state, bool) {
        	state.once = bool
        },
        
        logout(state) {
            localStorage.removeItem('token')
            state.token = null
        },
        
        changeConnect(state, params) {
            let connect = JSON.parse(state.connect)
            if(params.type) {
                connect.type = params.type
            }
            connect.status = params.status
            state.connect = JSON.stringify(connect)
        },
        
        setProduct(state, data) {
        	state.productList = data
        },
        
        changeProduct(state, data) {
            if(state.productList.length > 0) {
                state.productList = state.productList.map(item => {
                    if(data.symbol == item.key) {
                        item.price.close = data.close
                        item.price.open = data.open
                        item.price.high = data.high
                        item.price.low = data.low
                        item.price.number = data.number
                        item.price.total = data.total
                    }
                    return item
                })
            }
        },
        
        setCurCoin(state, data) {
        	state.curCoin = data
            localStorage.setItem('curCoin',JSON.stringify(data))
            state.productList.map((item,index) => {
                if(item.key == state.curCoin.key && index != 0) {
                    state.productList.splice(index,1)
                    state.productList.unshift(item)
                }
            })
        },
        
        setOrderBook(state, data) {
        	state.orderBook = data
        },
        
        setLatestRecord(state, data) {
        	state.latestRecord = data
        },
        
        setPerpetualOrder(state, data) {
			// console.log(data instanceof Array)
			if(data instanceof Array) {
				state.perpetualOrder = data
			} else {
				state.perpetualOrder = Object.values(data)
			}
        },
        
        setKline(state, kline) {
            if(state.curCoin.key === kline.symbol) {
                const data = kline.data
                state.curCoin.price.close = data[4]
            }
        },
        
        setCurTime(state, data) {
            state.curTime = data
        }
	},

	actions: {
		getProduct({ commit, state }) {
		    return Vue.prototype.$api.product().then(res => {
		        commit('setProduct', res.data)
                if(!localStorage.getItem('curCoin')) {
                    commit('setCurCoin', res.data[0])
                    localStorage.setItem('curCoin',JSON.stringify(res.data[0]))
                } else {
                    let old_coin = JSON.parse(localStorage.getItem('curCoin'))
                    let new_coin = {
                        id: '',
                        key: '',
                        kline: [],
                        name: '',
                        pic: '',
                        price: {
                            close: 0,
                            high: 0,
                            low: 0,
                            number: 0,
                            open: 0,
                            total: 0
                        },
                        priceDecimal: 2,
                        numberDecimal: 4,
                        unit: ''
                    }
                    res.data.forEach(item => {
                        if(item.key == old_coin.key) {
                            new_coin = item
                        }
                    })
                    commit('setCurCoin', new_coin)
                }
                
                return Promise.resolve(res.data)
		    })
		},
        
        getOrderBook({ commit, state }, key) {
            return Vue.prototype.$api.order_book({
                key
            }).then(res => {
                commit('setOrderBook', res.data)
            })
        },
        
        getLatestRecord({ commit, state }, key) {
            return Vue.prototype.$api.latest_record({
                key
            }).then(res => {
                commit('setLatestRecord', res.data)
            })
        }
	}
})

export default store
