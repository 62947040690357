import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import koLocale from 'element-ui/lib/locale/lang/ko'
import viLocale from 'element-ui/lib/locale/lang/vi'
import thLocale from 'element-ui/lib/locale/lang/th'
import zhCNLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhTWLocale from 'element-ui/lib/locale/lang/zh-TW'
import en from '@/lang/en.js'
import ja from '@/lang/ja.js'
import ko from '@/lang/ko.js'
import vi from '@/lang/vi.js'
import th from '@/lang/th.js'
import zh_CN from '@/lang/zh-CN.js'
import zh_TW from '@/lang/zh-TW.js'

Vue.use(VueI18n)

const messages = {
    'en': Object.assign(enLocale, en),
    'ja': Object.assign(jaLocale, ja),
    'ko': Object.assign(koLocale, ko),
    'vi': Object.assign(viLocale, vi),
    'th': Object.assign(thLocale, th),
    'zh-CN': Object.assign(zhCNLocale, zh_CN),
    'zh-TW': Object.assign(zhTWLocale, zh_TW),
}
//将vuei18n实例化，并传入配置信息
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en', //当前语种，locale用来设置语种包
    fallbackLocale: 'en', //当前语种不存在时，默认设置的当前语种
    messages, //本地语言包
})
locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n
