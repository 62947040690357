// 引入Loading
// import { Loading } from 'element-ui';
// let loadingInstance = Loading.service({
//     customClass: 'loading-style',
//     text: '正在处理...',
//     spinner: 'el-icon-loading',
//     background: 'rgba(0, 0, 0, 0.8)'
// });
/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import { Message } from 'element-ui'
import {
    base_url,
    get_urls,
    post_urls,
    login_url
} from './api.js'

import axios from 'axios';
// import QS from 'qs';

// 接口地址
axios.defaults.baseURL = base_url

// 请求超时时间
axios.defaults.timeout = 10000

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        return config
    },
    error => {
        return Promise.error(error)
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        let status_code = response.data.code
        let message = response.data.message
        
        if(status_code == 200) {
            return Promise.resolve(response)
        } else if(status_code == 401) {
            store.commit('logout')
            // const redirect = location.href.split(`/#`)[1]
            const body_width = document.body.clientWidth
            if(body_width <= 800) {
                router.push({ path: '/h5/login' })
            } else {
                router.push({ path: '/login' })
            }
            return Promise.reject(response)
        } else {
            Message.error({
                message
            })
            return Promise.reject(response)
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录                
                // 未登录则跳转登录页面，并携带当前页面的路径                
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:
                    // router.replace({
                    //     path: '/login',
                    //     query: {
                    //         redirect: router.currentRoute.fullPath
                    //     }
                    // });
                    // break;
                    // 403 token过期                
                    // 登录过期对用户进行提示                
                    // 清除本地token和清空vuex中token对象                
                    // 跳转登录页面                
                case 403:
                    // Toast({
                    //     message: '登录过期，请重新登录',
                    //     duration: 1000,
                    //     forbidClick: true
                    // });
                    // // 清除token                    
                    // localStorage.removeItem('token');
                    // store.commit('loginSuccess', null);
                    // // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    // setTimeout(() => {
                    //     router.replace({
                    //         path: '/login',
                    //         query: {
                    //             redirect: router.currentRoute.fullPath
                    //         }
                    //     });
                    // }, 1000);
                    // break;
                    // 404请求不存在                            
                default:
                    // Toast({
                    //     message: error.response.data.message,
                    //     duration: 1500,
                    //     forbidClick: true
                    // });
            }
            return Promise.reject(error.response)
        }
    }
)

function requestDeal() {
    // post请求头
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token') || ''}`
    
    // 获取当前语言
    let curLang = localStorage.getItem('lang')
    if(!curLang){
        localStorage.setItem('lang', 'en')
    }
    axios.defaults.headers.post['lang'] = localStorage.getItem('lang')
}

Vue.prototype.$api = {}

for (let name in get_urls) {
    Vue.prototype.$api[name] = (params, cacheTime = false) => {
        requestDeal()
        let curLang = localStorage.getItem('lang')
        if (cacheTime !== false) {
            let res = localStorage.getItem('api-cache-' + name + '-' + JSON.stringify(params))
            res = JSON.parse(res)
            if (res && res.expire > Date.now() && curLang == res.lang) {
                // uni.hideLoading();
                return Promise.resolve(res.data)
            }
        }
        return new Promise((resolve, reject) => {
            axios.get(get_urls[name], params)
                .then(res => {
                    if (cacheTime !== false) {
                        localStorage.setItem('api-cache-' + name + '-' + JSON.stringify(params),
                            JSON.stringify({
                                lang: curLang,
                                expire: Date.now() + cacheTime,
                                data: res.data
                            }))
                    }

                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    }
}

for (let name in post_urls) {
    Vue.prototype.$api[name] = (params, cacheTime = false) => {
        requestDeal()
        let curLang = localStorage.getItem('lang')
        if (cacheTime !== false) {
            let res = localStorage.getItem('api-cache-' + name + '-' + JSON.stringify(params))
            res = JSON.parse(res)
            if (res && res.expire > Date.now() && curLang == res.lang) {
                // uni.hideLoading();
                return Promise.resolve(res.data)
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(post_urls[name], params)
                .then(res => {
                    if (cacheTime !== false) {
                        localStorage.setItem('api-cache-' + name + '-' + JSON.stringify(params),
                            JSON.stringify({
                                lang: curLang,
                                expire: Date.now() + cacheTime,
                                data: res.data
                            }))
                    }

                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    }
}
