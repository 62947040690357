export default {
    "Module": {
        "Home": "บ้าน",
        "PerpetualContract": "อนาคตนิรันดร์",
        "CurrencyTrading": "การทำธุรกรรมสกุลเงิน",
        "SecondContract": "สัญญาออปชัน",
        "Finance": "การเงิน",
        "Announcement": "ประกาศ",
        "Assets": "สินทรัพย์",
        "Account": "บัญชี"
    },
    "Login": {
        "Login": "เข้าสู่ระบบ",
        "Register": "ลงทะเบียน",
        "RetrievePassword": "ดึงรหัสผ่าน",
        "Type": "อีเมล",
        "Email": "อีเมล",
        "Password": "รหัสผ่าน",
        "ConfirmPassword": "ยืนยันรหัสผ่าน",
        "VerificationCode": "รหัสยืนยัน",
        "Send": "ส่ง",
        "PhoneNumber": "หมายเลขโทรศัพท์",
        "InvitationCode": "รหัสการเชิญ",
        "Agree": "ฉันได้อ่านและเห็นด้วย",
        "UserAgreement": "ข้อตกลงการใช้",
        "EnterEmail": "กรุณากรอกอีเมล์ของคุณ",
        "EnterEmailError": "กรุณากรอกรูปแบบอีเมลที่ถูกต้อง",
        "EnterPassword": "กรุณาใส่รหัสผ่านของคุณ",
        "EnterPasswordError": "รูปแบบรหัสผ่านคือ 6 ถึง 20 ตัวอักษร",
        "EnterConfirmPassword": "กรุณายืนยันรหัสผ่านของคุณ",
        "EnterConfirmPasswordError": "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน",
        "EnterEmailFirst": "กรุณากรอกอีเมล์ของคุณก่อน",
        "EnterVerificationCode": "กรุณากรอกรหัสยืนยัน",
        "EnterPhoneNumber": "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
        "EnterInvitationCode": "กรุณากรอกรหัสเชิญ",
        "ConfirmAgreement": "โปรดอ่านและยอมรับข้อตกลงผู้ใช้",
        "Forget": "ลืมรหัสผ่าน?"
    },
    "Home": {
        "CustomerService": "บริการลูกค้า",
        "TcTitle": "คำประกาศ",
        "TopTitle1": "เริ่มต้นการลงทุนสกุลเงินดิจิตอลของคุณอย่างรวดเร็ว",
        "TopTitle2": "BitVenus เป็นสถานที่ที่น่าเชื่อถือและปลอดภัยที่สุดในการซื้อและขายสกุลเงินดิจิทัล",
        "EmailAddress": "ที่อยู่อีเมล",
        "StartUsing": "เริ่มใช้",
        "SeeMore": "ดูเพิ่มเติม",
        "MarketTrend": "แนวโน้มตลาด",
        "Currency": "สกุลเงิน",
        "LatestPrice": "ราคาล่าสุด",
        "Change": "การเปลี่ยนแปลง 24 ชั่วโมง",
        "TradingVolume": "ปริมาณการซื้อขายตลอด 24 ชั่วโมง",
        "Trend": "แนวโน้ม",
        "Operate": "ดำเนินงาน",
        "Trade": "ซื้อขาย",
        "AllTradingVolume": "ปริมาณการซื้อขายแลกเปลี่ยนตลอด 24 ชั่วโมง",
        "SupportedCountries": "ประเทศที่ได้รับการสนับสนุน",
        "RegisterNumber": "จำนวนผู้ใช้ที่ลงทะเบียน",
        "Box4Title": "แพลตฟอร์ม Cryptocurrency ที่น่าเชื่อถือที่สุด",
        "Box4Desc": "นี่คือเหตุผลที่คุณควรเลือก BitVenus",
        "Box4TabTitles": [
            "ธุรกรรมที่หลากหลาย",
            "ค่าธรรมเนียมขั้นต่ำ",
            "การรักษาความปลอดภัยชั้นนำ",
            "สุดยอดประสบการณ์ผู้ใช้"
        ],
        "Box4TabDescs": [
            "BitVenus จัดทำรายการสั่งซื้อที่มีสภาพคล่องสูงสุด การซื้อขายสกุลเงินดิจิตอลที่หลากหลาย และบริการการจัดการสินทรัพย์ เพื่อตอบสนองความต้องการการซื้อขายที่หลากหลาย",
            "ด้วยการให้เทรดเดอร์สามารถเข้าถึงตลาดการเงินแบบเพียร์ทูเพียร์ BitVenus เลือกคู่การซื้อขายสกุลเงินดิจิทัลเฉพาะที่มีค่าธรรมเนียมการทำธุรกรรมต่ำที่สุด",
            "BitVenus ใช้วิธีการจัดเก็บข้อมูลแบบออฟไลน์ และรับประกันข้อมูลธุรกรรมส่วนบุคคลผ่านการเข้ารหัสจากต้นทางถึงปลายทาง และแพลตฟอร์มจะตรวจสอบแบบเรียลไทม์เพื่อปกป้องความปลอดภัยของทรัพย์สินของผู้ใช้อย่างเต็มที่",
            "ผู้ใช้ต้องมาก่อน ใช้งานง่าย รับฟังความคิดเห็น เพิ่มประสิทธิภาพผลิตภัณฑ์อย่างรวดเร็ว ออนไลน์แบบเรียลไทม์ 7*24 ชั่วโมง บริการระดับมืออาชีพแบบตัวต่อตัวหลายภาษา"
        ],
        "Box5Title": "ปรับปรุงสายผลิตภัณฑ์อย่างต่อเนื่องเพื่อตอบสนองความต้องการของผู้ใช้ที่หลากหลาย",
        "Box5TabTitles": [
            "อนาคตนิรันดร์",
            "การทำธุรกรรมสกุลเงิน",
            "สัญญาออปชัน",
            "การเงิน"
        ],
        "Box5TabDescs": [
            "เลเวอเรจการซื้อขาย โดยใช้เลเวอเรจเพื่อใช้เงินทุนของตัวเองเป็นมาร์จิ้น ยืม N คูณเงินทุนจากแพลตฟอร์มเป็นหลัก และทำการลงทุนเพื่อให้ได้ผลกำไร",
            "ใช้สกุลเงินดิจิทัลเป็นหน่วยราคา ซื้อสกุลเงินดิจิทัลประเภทอื่น ซื้อและขายสกุลเงินดิจิทัลอย่างรวดเร็วในราคาที่ดีที่สุด และรับผลตอบแทนที่ดีที่สุด",
            "อนุพันธ์ทางการเงิน โดยการวิเคราะห์สภาวะตลาดระยะสั้น เทรดเดอร์จะซื้อหรือขายในราคาที่กำหนดไว้ล่วงหน้าในเวลาที่กำหนด และซื้อขายด้วยความถี่ที่รวดเร็วเพื่อรับผลกำไร",
            "อัตราผลตอบแทนต่อปีที่แข่งขันได้สูง รับประกันความปลอดภัยของเงินทุนชั้นหนึ่ง สร้างรายได้ง่าย ๆ !"
        ],
        "TradeNow": "ซื้อขายตอนนี้",
        "Box6Title1": "เริ่มการทำธุรกรรมทุกที่ทุกเวลา",
        "Box6Title2": "ทั้ง APP และ WEB สามารถเปิดธุรกรรมได้อย่างรวดเร็ว",
        "FooterItems1": [
            "ข้อกำหนดและนโยบาย",
            "อัตรามาตรฐาน",
            "คำเตือนความเสี่ยง",
            "ป้องกันการฟอกเงิน",
            "เกี่ยวกับเรา"
        ],
        "FooterItems2": [
            "ความเป็นส่วนตัวของบริการ",
            "เงื่อนไขการให้บริการ",
            "คำชี้แจงสิทธิส่วนบุคคล",
            "กระดาษสีขาว",
            "ใบรับรอง MSB"
        ],
        "FooterItems3": [
            "ศูนย์ช่วยเหลือ",
            "คู่มือสำหรับผู้เริ่มต้น",
            "คู่มือการใช้งาน",
            "ติดต่อเรา"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "กรุณากรอกชื่อสกุลเงิน",
        "Currency": "สกุลเงิน",
        "LatestPrice": "ราคาล่าสุด",
        "Change": "เปลี่ยน",
        "Highest": "สูง 24 ชม",
        "Lowest": "ต่ำ 24 ชม",
        "OrderBook": "สั่งหนังสือ",
        "LatestRecord": "บันทึกล่าสุด",
        "All": "ทั้งหมด",
        "Bids": "การเสนอราคา",
        "Asks": "ถาม",
        "Price": "ราคา",
        "Quantity": "ปริมาณ",
        "Total": "ทั้งหมด",
        "Time": "เวลา",
        "AveragePrice": "ราคาเฉลี่ย",
        "EnterPayPass": "กรุณากรอกรหัสผ่านการทำธุรกรรม",
        "BuyTips": "คุณแน่ใจหรือไม่ว่าต้องการซื้อ?",
        "type": "พิมพ์"
    },
    "PerpetualContract": {
        "OrderType": "ประเภทการสั่งซื้อ",
        "Market": "ตลาด",
        "EnterQuantity": "ป้อนปริมาณการซื้อ",
        "HandlingFee": "ค่าธรรมเนียมการจัดการ",
        "AvailableBalance": "ยอดเงินคงเหลือ",
        "Lever": "คันโยก",
        "Fee": "ค่าธรรมเนียม",
        "OpenLong": "เปิดยาวๆ",
        "OpenShort": "เปิดสั้น",
        "CurrentOrders": "คำสั่งซื้อปัจจุบัน",
        "OrderHistory": "ประวัติการสั่งซื้อ",
        "Margin": "ขอบ",
        "CurrentPrice": "ราคาปัจจุบัน",
        "OpeningPrice": "ราคาเปิด",
        "UnsettledProfit": "กำไรไม่แน่นอน",
        "Operate": "ดำเนินงาน",
        "OpenTime": "เวลาเปิด",
        "ClosingTime": "เวลาปิด",
        "ClosingPrice": "ราคาปิด",
        "SettlementProfit": "กำไรจากการชำระบัญชี",
        "ClosePosition": "ปิดตำแหน่ง",
        "Edit": "แก้ไข",
        "EditOrder": "แก้ไขคำสั่งซื้อ",
        "EnterCloseQuantity": "กรุณากรอกจำนวน",
        "ConfirmOrder": "ยืนยันรายการ",
        "TakeProfitPrice": "ราคาขายทำกำไร",
        "StopPrice": "หยุดราคา"
    },
    "CurrencyTrading": {
        "Buy": "ซื้อ",
        "Sell": "ขาย",
        "MyBalance": "ความสมดุลของฉัน",
        "AvailablePurchaseAmount": "จำนวนเงินที่สามารถซื้อได้",
        "ConvertibleAmount": "จำนวนเงินที่สามารถแปลงสภาพได้",
        "CurrentPrice": "ราคาปัจจุบัน",
        "BuyQuantity": "ซื้อปริมาณ",
        "EnterBuyQuantity": "ป้อนจำนวนที่จะซื้อ",
        "SellQuantity": "ขายปริมาณ",
        "EnterSellQuantity": "ป้อนจำนวนที่จะขาย",
        "RequiredAmount": "จำนวนเงินที่ต้องการ",
        "ExchangeAmount": "จำนวนการแลกเปลี่ยน",
        "OrderHistory": "ประวัติการสั่งซื้อ",
        "Status": "สถานะ",
        "Success": "ความสำเร็จ"
    },
    "SecondContract": {
        "Buy": "ซื้อ",
        "OpeningQuantity": "ปริมาณการเปิด",
        "EnterOpeningQuantity": "ป้อนจำนวนสกุลเงิน",
        "SelectQuantity": "เลือกปริมาณ",
        "OpeningTime": "เวลาเปิดทำการ",
        "minute": "นาที",
        "Balance": "สมดุล",
        "Transfer": "โอนย้าย",
        "HandlingFee": "ค่าธรรมเนียมการจัดการ",
        "Profitability": "การทำกำไร",
        "BuyUp": "ซื้อ",
        "BuyDown": "ซื้อลง",
        "InTransaction": "ในการทำธุรกรรม",
        "Closed": "ปิด",
        "OrderNumber": "หมายเลขคำสั่งซื้อ",
        "CurrentPrice": "ราคาปัจจุบัน",
        "Direction": "ทิศทาง",
        "EstimatedProfitLoss": "ประมาณการกำไรและขาดทุน",
        "Countdown": "นับถอยหลัง",
        "OpeningPrice": "ราคาเปิด",
        "ClosingPrice": "ราคาปิด",
        "ProfitAndLoss": "กำไรและขาดทุน",
        "Scheduled": "กำหนดเวลาแล้ว",
        "ChooseTime": "กรุณาเลือกเวลาซื้อ",
        "ChooseDuration": "เลือกระยะเวลาการซื้อ"
    },
    "Finance": {
        "TopTitle": "ขุมทรัพย์ทางการเงิน",
        "TopDesc": "เป็นสกุลเงินที่ถือครองสินทรัพย์บล็อคเชนเพื่อรับดอกเบี้ย แพลตฟอร์มการลงทุนที่สร้างรายได้ที่มั่นคง รวมถึงการจัดการทางการเงินแบบคงที่ (ล็อคการจัดการทางการเงิน) การจัดการทางการเงินในปัจจุบัน และผลิตภัณฑ์ประเภทอื่น ๆ ซึ่งเป็นช่องทางการลงทุนที่ดีที่สุดสำหรับผู้ใช้การลงทุนที่มั่นคง",
        "AllProducts": "สินค้าทั้งหมด",
        "InProgress": "กำลังดำเนินการ",
        "AboutToStart": "กำลังจะเริ่มต้นแล้ว",
        "ItsOver": "มันจบแล้ว",
        "FinancialRecords": "บันทึกทางการเงิน",
        "ReferenceAnnualized": "อ้างอิงเป็นรายปี",
        "day": "วัน",
        "hour": "ชั่วโมง",
        "minute": "นาที",
        "second": "ที่สอง",
        "Unlock": "ปลดล็อค",
        "RemainingPosition": "ตำแหน่งที่เหลืออยู่",
        "LockedPositions": "ตำแหน่งที่ถูกล็อค",
        "EndOfDistance": "สิ้นสุดระยะทาง",
        "JoinNow": "เข้าร่วมเดี๋ยวนี้",
        "OurAdvantage": "ข้อได้เปรียบของเรา",
        "AdvanListTitles": [
            "รายได้ที่มีประสิทธิภาพ",
            "รอบที่สั้นลง",
            "ปลอดภัยและเชื่อถือได้"
        ],
        "AdvanListDescs": [
            "ใช้การผสมผสานเครื่องมือทางการเงินระดับมืออาชีพที่หลากหลายเพื่อสร้างผลิตภัณฑ์ทางการเงินคุณภาพสูงที่ให้ผลตอบแทนสูงสำหรับผู้ใช้",
            "วงจรนี้มีความยืดหยุ่นมากขึ้น และการล็อคจะสั้นเพียง 7 วัน",
            "โซลูชันการจัดการและการควบคุมความเสี่ยงระดับมืออาชีพช่วยปกป้องผลตอบแทนของสินทรัพย์"
        ],
        "JoinNum": "มีผู้เข้าร่วม {joinNum} คน",
        "DailyRate": "อัตรารายวัน",
        "StartingTime": "เวลาเริ่มต้น",
        "LockPeriod": "ช่วงกักตัว",
        "RebateMethod": "วิธีการคืนเงิน",
        "RebateMethod1": "เงินต้นคืนเมื่อครบกำหนด",
        "RebateMethod2": "ส่วนลดรายวัน",
        "LockPosition": "ตำแหน่งล็อค",
        "ProductSpecification": "คุณสมบัติของผลิตภัณฑ์",
        "LockedCurrency": "สกุลเงินที่ถูกล็อค",
        "SubscriptionAmount": "จำนวนการสมัครสมาชิก",
        "MinAndMax": "ขั้นต่ำ/สูงสุด",
        "TotalPosition": "รวมตำแหน่ง",
        "TradingRules": "กฎการซื้อขาย",
        "BuyProductTips": "คุณแน่ใจหรือไม่ว่าต้องการสมัครสมาชิกผลิตภัณฑ์นี้?",
        "Pending": "รอดำเนินการ",
        "Completed": "สมบูรณ์",
        "ProductName": "ชื่อผลิตภัณฑ์",
        "PurchaseAmount": "จำนวนการซื้อ",
        "CumulativeIncome": "รายได้สะสม",
        "EndTime": "เวลาสิ้นสุด"
    },
    "Assets": {
        "FinancialRecords": "บันทึกทางการเงิน",
        "Deposit": "เงินฝาก",
        "Withdraw": "ถอน",
        "Transfer": "โอนย้าย",
        "TotalBalance": "ยอดรวม",
        "Refresh": "รีเฟรช",
        "Currency": "สกุลเงิน",
        "AvailableBalance": "ยอดเงินคงเหลือ",
        "Frozen": "แช่แข็ง",
        "Operate": "ดำเนินงาน",
        "Type": "พิมพ์",
        "Time": "เวลา",
        "Quantity": "ปริมาณ",
        "SelectCurrency": "เลือกสกุลเงิน",
        "Address": "ที่อยู่",
        "Notice": "สังเกต",
        "DepositAmount": "จำนวนเงินฝาก",
        "EnterDepositAmount": "กรุณากรอกจำนวนเงินฝาก",
        "PaymentVoucher": "ใบสำคัญการชำระเงิน",
        "UploadPaymentVoucher": "กรุณาอัพโหลดใบสำคัญการชำระเงิน",
        "DepositRecord": "บันทึกการฝากเงิน",
        "OrderNumber": "หมายเลขคำสั่งซื้อ",
        "Status": "สถานะ",
        "Status1": "กำลังดำเนินการ",
        "Status2": "สมบูรณ์",
        "Status3": "ล้มเหลว",
        "Status4": "ผิดปกติ",
        "Status5": "ยกเลิก",
        "WithdrawalAmount": "จำนวนเงินที่ถอน",
        "Least": "น้อยที่สุด",
        "All": "ทั้งหมด",
        "WithdrawalCurrency": "สกุลเงินที่ถอนออก",
        "WithdrawalAddress": "ที่อยู่การถอนเงิน",
        "HandlingFee": "ค่าธรรมเนียมการจัดการ",
        "ReceiptAmount": "จำนวนใบเสร็จรับเงิน",
        "TransactionPassword": "รหัสผ่านการทำธุรกรรม",
        "SetTransactionPassword": "ตั้งรหัสผ่านการทำธุรกรรม",
        "GoogleVerificationCode": "รหัสยืนยันของ Google",
        "EnterWithdrawalAmount": "กรุณากรอกจำนวนเงินที่ถอน",
        "EnterWithdrawalAddress": "กรุณากรอกที่อยู่การถอนเงิน",
        "EnterTransactionPassword": "กรุณากรอกรหัสผ่านการทำธุรกรรม",
        "EnterGoogleVerificationCode": "กรุณากรอกรหัสยืนยันของ Google",
        "WithdrawalsRecord": "บันทึกการถอนเงิน",
        "ExchangeCurrency": "แลกเปลี่ยนสกุลเงิน",
        "ExchangeAmount": "จำนวนการแลกเปลี่ยน",
        "ExchangeRate": "อัตราแลกเปลี่ยน",
        "Expected": "ที่คาดหวัง",
        "TransferRecord": "บันทึกการโอน",
        "CurrencyTransfer": "สกุลเงินที่จะโอน",
        "Success": "ความสำเร็จ",
        "EnterExchangeAmount": "กรุณากรอกจำนวนเงินที่จะแลกเปลี่ยน"
    },
    "Account": {
        "Logout": "ออกจากระบบ",
        "PersonalCenter": "ศูนย์ส่วนบุคคล",
        "LoginHistory": "ประวัติการเข้าสู่ระบบ",
        "Time": "เวลา",
        "Account": "บัญชี",
        "LoginPassword": "รหัสผ่านการเข้าใช้งาน",
        "Modify": "แก้ไข",
        "TransactionPassword": "รหัสผ่านการทำธุรกรรม",
        "SetTransactionPassword": "ตั้งรหัสผ่านการทำธุรกรรม",
        "ResetTransactionPassword": "รีเซ็ตรหัสผ่านการทำธุรกรรม",
        "Recommend": "แนะนำ",
        "MobileAuth": "การรับรองความถูกต้องผ่านมือถือ",
        "BindMailbox": "ผูกกล่องจดหมาย",
        "GoogleAuth": "การรับรองความถูกต้องของ Google",
        "KYCCertification": "การรับรองKYC",
        "MobileAuthDesc": "ใช้เพื่อดึงรหัสผ่านและแก้ไขการตั้งค่าความปลอดภัยสำหรับการตรวจสอบความปลอดภัย",
        "BindMailboxDesc": "ใช้เพื่อดึงรหัสผ่านและแก้ไขการตั้งค่าความปลอดภัยสำหรับการตรวจสอบความปลอดภัย",
        "GoogleAuthDesc": "ใช้เพื่อถอนสกุลเงินเพื่อตรวจสอบความปลอดภัย",
        "Add": "เพิ่ม",
        "Remove": "ลบ",
        "GoAuth": "ไปที่การรับรองความถูกต้อง",
        "Verified": "ตรวจสอบแล้ว",
        "UnderReview": "ภายใต้การทบทวน",
        "ChangeLoginPassword": "เปลี่ยนรหัสผ่านเข้าสู่ระบบ",
        "ChangeLoginPasswordTips": "คุณไม่สามารถถอนเงินสดได้ภายใน 24 ชั่วโมงหลังจากเปลี่ยนรหัสผ่านเข้าสู่ระบบแล้ว โปรดดำเนินการด้วยความระมัดระวัง",
        "OriginalPassword": "ป้อนรหัสผ่านเดิม",
        "NewPassword": "ป้อนรหัสผ่านใหม่",
        "ConfirmPassword": "ป้อนรหัสผ่านใหม่อีกครั้ง",
        "EnterOriginalPassword": "กรุณากรอกรหัสผ่านเดิม",
        "EnterNewPassword": "กรุณากรอกรหัสผ่านใหม่",
        "EnterConfirmPassword": "กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
        "EnterPasswordError": "รูปแบบรหัสผ่านคือ 6 ถึง 20 ตัวอักษร",
        "EnterConfirmPasswordError": "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน",
        "ChangeTransactionPasswordTips": "คุณไม่สามารถถอนเงินสดได้ภายใน 24 ชั่วโมงหลังจากเปลี่ยนรหัสผ่านการทำธุรกรรมแล้ว โปรดดำเนินการด้วยความระมัดระวัง",
        "EnterLoginPassword": "ป้อนรหัสผ่านเข้าสู่ระบบ",
        "PleaseEnterLoginPassword": "กรุณาใส่รหัสผ่านของคุณ",
        "AddGoogleAuth": "เพิ่ม Google Authenticator",
        "Step1": "ขั้นตอนที่ 1 ดาวน์โหลดแอพ Google Authenticator",
        "Step2": "ขั้นตอนที่ 2 เพิ่มรหัสใน Google Authenticator และสำรองข้อมูล",
        "Step3": "ขั้นตอนที่ 3 ป้อนรหัสยืนยัน 6 หลักจาก Google Validator",
        "Step1Desc": "ผู้ใช้ iOS เข้าสู่ระบบ App Store ค้นหา Authenticator เพื่อดาวน์โหลด<br/><br/> ผู้ใช้ Android เข้าสู่ระบบ App Store หรือใช้เบราว์เซอร์มือถือเพื่อค้นหา Google Authenticator เพื่อดาวน์โหลด",
        "Step2Desc": "เปิด Google Authenticator สแกนโค้ด QR ด้านล่างหรือป้อนคีย์ด้านล่างด้วยตนเองเพื่อเพิ่มโทเค็นการยืนยัน",
        "CopyKey": "คัดลอกคีย์",
        "EnterGoogleVerificationCode": "ป้อนรหัสยืนยันของ Google",
        "PleaseEnterGoogleVerificationCode": "กรุณากรอกรหัสยืนยันของ Google",
        "RemoveGoogleAuth": "ลบเครื่องมือตรวจสอบ Google",
        "RemoveGoogleAuthTips": "คุณไม่สามารถถอนเงินสดได้ภายใน 24 ชั่วโมงหลังจากลบ Google Validator แล้ว โปรดดำเนินการด้วยความระมัดระวัง",
        "UploadIDPhoto": "อัปโหลดรูปถ่ายประจำตัว",
        "UploadIDPhotoTips": "อัปโหลดภาพสีของทั้งเอกสาร ไม่อนุญาตให้ใช้ภาพหน้าจอ ขนาดของรูปภาพต้องไม่เกิน 10M และสามารถอยู่ในรูปแบบ JPG หรือ PNG เท่านั้น",
        "FrontPhoto": "ภาพถ่ายด้านหน้าบัตรประจำตัวประชาชน",
        "BackPhoto": "ภาพถ่ายด้านหลังของบัตรประจำตัวประชาชน",
        "FrontPhotoTips": "คลิกเพื่ออัพโหลดรูปถ่ายด้านหน้าบัตรประจำตัวประชาชน",
        "BackPhotoTips": "คลิกเพื่ออัพโหลดรูปถ่ายด้านหลังบัตรประจำตัวประชาชนของคุณ"
    },
    "FundingType": {
        "All": "ทั้งหมด",
        "system": "การทำงานของระบบ",
        "commission": "คณะกรรมการ",
        "recharge": "เติมเงิน",
        "withdraw": "ถอน",
        "withdrawFail": "การถอนเงินล้มเหลว",
        "second_order": "คำสั่งสัญญาทางเลือก",
        "secondOrderWin": "สัญญาออปชั่นชนะ",
        "cancelSOrder": "ยกเลิกคำสั่งสัญญาออปชั่น",
        "perpetual_order": "เงินมัดจำการสั่งซื้อล่วงหน้าแบบไม่จำกัดระยะเวลา + ค่าธรรมเนียมการจัดการ",
        "perpeTualOrderClo": "การปิดฟิวเจอร์สถาวร",
        "trade": "การทำธุรกรรมสกุลเงิน",
        "buyFinance": "ซื้อไฟแนนซ์",
        "financeProfit": "ส่วนลดทางการเงิน",
        "financeMoney": "การเงินกลับเงินต้น"
    },
    "Common": {
        "Language": "ภาษา",
        "Tips": "เคล็ดลับ",
        "Confirm": "ยืนยัน",
        "Cancel": "ยกเลิก",
        "Submit": "ส่ง",
        "copy": "สำเนา",
        "CopySuccessfully": "คัดลอกสำเร็จ",
        "NoData": "ไม่มีข้อมูล",
        "Loading": "ซึ่งรอคอย...",
        "Back": "กลับ",
        "Details": "รายละเอียด"
    }
}
